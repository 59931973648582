/* eslint-disable vue/no-unused-vars */
<template>
  <div>
    <vl-region mod-no-space-top>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <vl-title class="vl-u-spacer--none">{{ $t('organizations.label') }}</vl-title>
            <vl-data-table>
              <thead>
                <tr>
                  <th></th>
                  <th v-for="column in organizationColumns">
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(organization, organizationIndex) in organizations">
                  <tr
                    :key="organization.id"
                    :class="{ opened: opened.includes(organizationIndex) }"
                  >
                    <td>
                      <button
                        @click="toggle(organizationIndex)"
                        class="btn waves-effect waves-light yellow darken-2"
                      >
                        <i v-if="!opened.includes(organizationIndex)" class="material-icons"
                          >expand_more</i
                        >
                        <i v-if="opened.includes(organizationIndex)" class="material-icons"
                          >expand_less</i
                        >
                      </button>
                    </td>
                    <td>
                      {{ organization.id }}
                    </td>
                    <td>
                      {{ organization.name }}
                    </td>
                    <td>{{ organization.orafin }}</td>
                    <td>{{ organization.pomUsername }}</td>
                    <td>{{ organization.pomPassword }}</td>

                    <td style="width: 18%;">
                      <button
                        @click="editOrganization(organizationIndex)"
                        class="btn waves-effect waves-light yellow darken-2"
                      >
                        <i class="material-icons">edit</i>
                      </button>
                      <button
                        @click="deleteOrganization(organizationIndex)"
                        class="btn waves-effect waves-light red darken-2"
                      >
                        <i class="material-icons">delete</i>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="opened.includes(organizationIndex)">
                    <td></td>
                    <td colspan="3">
                      <vl-data-table>
                        <thead>
                          <tr>
                            <th v-for="column in tenantColumns">
                              {{ column }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(tenant, tenantIndex) in organization.tenants"
                            :key="tenant.id"
                          >
                            <td>{{ tenant.id }}</td>
                            <td>{{ tenant.senderContractNumber }}</td>
                            <td>{{ tenant.senderId }}</td>
                            <td>{{ tenant.dueDays }}</td>
                            <td>{{ tenant.expiryDays }}</td>
                            <td style="width: 18%;">
                              <button
                                @click="editTenant(organizationIndex, tenantIndex)"
                                class="btn waves-effect waves-light yellow darken-2"
                              >
                                <i class="material-icons">edit</i>
                              </button>
                              <button
                                @click="deleteTenant(organizationIndex, tenantIndex)"
                                class="btn waves-effect waves-light red darken-2"
                              >
                                <i class="material-icons">delete</i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1">
                              <div class="input-field">
                                <div class="vl-form-col--12-12">
                                  <input
                                    v-model="tenantInput[organizationIndex].id"
                                    id="id"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="input-field">
                                <div class="vl-form-col--12-12">
                                  <input
                                    v-model="tenantInput[organizationIndex].senderContractNumber"
                                    id="name"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="input-field">
                                <div class="vl-form-col--12-12">
                                  <input
                                    v-model="tenantInput[organizationIndex].senderId"
                                    id="name"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="input-field">
                                <div class="vl-form-col--12-12">
                                  <input
                                    v-model="tenantInput[organizationIndex].dueDays"
                                    id="dueDays"
                                    type="number"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="input-field">
                                <div class="vl-form-col--12-12">
                                  <input
                                    v-model="tenantInput[organizationIndex].expiryDays"
                                    id="expiryDays"
                                    type="number"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="addTenant(organizationIndex, organization)"
                                class="btn btn-waves green darken-2"
                              >
                                <i class="material-icons">add</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </vl-data-table>
                    </td>
                  </tr>
                </template>
                <tr>
                  <td></td>
                  <td colspan="1">
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input ref="id" v-model="organizationInput.id" id="id" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input v-model="organizationInput.name" id="name" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input v-model="organizationInput.orafin" id="orafin" type="checkbox" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input
                          v-model="organizationInput.pomUsername"
                          id="pomUsername"
                          type="text"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="input-field">
                      <div class="vl-form-col--12-12">
                        <input
                          v-model="organizationInput.pomPassword"
                          id="pomPassword"
                          type="text"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <button @click="addOrganization" class="btn btn-waves green darken-2">
                      <i class="material-icons">add</i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </vl-data-table>
            <div
              id="tenantModal"
              class="modal fade"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="center-align">{{ $t('tenants.modal.title') }}</h4>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <form class="col s12">
                        <div class="row">
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="name">{{
                                $t('tenants.modal.senderContractNumber')
                              }}</label>
                              <input
                                id="name"
                                class="form-control"
                                type="text"
                                v-model="tenantEditInput.senderContractNumber"
                              />
                            </div>
                          </div>
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="name">{{ $t('tenants.modal.senderId') }}</label>
                              <input
                                id="name"
                                class="form-control"
                                type="text"
                                v-model="tenantEditInput.senderId"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="expiryDays">{{ $t('tenants.modal.expiryDays') }}</label>
                              <input
                                id="expiryDays"
                                class="form-control"
                                type="number"
                                v-model="tenantEditInput.expiryDays"
                              />
                            </div>
                          </div>
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="dueDays">{{ $t('tenants.modal.dueDays') }}</label>
                              <input
                                id="dueDays"
                                class="form-control"
                                type="number"
                                v-model="tenantEditInput.dueDays"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                      {{ $t('tenants.modal.close') }}
                    </button>
                    <button
                      @click="updateTenant"
                      type="button"
                      class="btn btn-primary"
                      data-dismiss="modal"
                    >
                      {{ $t('tenants.modal.update') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="organizationModal"
              class="modal fade"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="center-align">{{ $t('organizations.modal.title') }}</h4>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <form class="col s12">
                        <div class="row">
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="name">{{ $t('organizations.modal.name') }}</label>
                              <input
                                id="name"
                                class="form-control"
                                type="text"
                                v-model="organizationEditInput.name"
                              />
                            </div>
                          </div>
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="orafin">{{ $t('organizations.modal.orafin') }}</label>
                              <input
                                id="orafin"
                                class="form-control move-left"
                                type="checkbox"
                                v-model="organizationEditInput.orafin"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="pom_username">{{
                                $t('organizations.modal.pomUsername')
                              }}</label>
                              <input
                                id="pom_username"
                                class="form-control"
                                type="text"
                                v-model="organizationEditInput.pomUsername"
                              />
                            </div>
                          </div>
                          <div class="input-field col s6">
                            <div class="form-group">
                              <label for="pom_password">{{
                                $t('organizations.modal.pomPassword')
                              }}</label>
                              <input
                                id="pom_password"
                                class="form-control"
                                type="text"
                                v-model="organizationEditInput.pomPassword"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                      {{ $t('organizations.modal.close') }}
                    </button>
                    <button
                      @click="updateOrganization"
                      type="button"
                      class="btn btn-primary"
                      data-dismiss="modal"
                    >
                      {{ $t('organizations.modal.update') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  async created() {
    try {
      this.$store.dispatch('organization/getAll').then(response => {
        this.organizations = response;
        this.organizations.forEach(organization => {
          this.$store.dispatch('tenant/getAllByOrganizationId', organization.id).then(response => {
            organization.tenants = [];
            organization.tenants = [...response];
          });
          this.tenantInput.push({ id: '', senderContractNumber: '', senderId: '' });
        });
      });
    } catch (err) {
      this.error = err.message;
    }
  },
  data() {
    return {
      opened: [],
      organizationColumns: ['Id', 'Naam', 'Orafin', 'POM Gebruikersnaam', 'POM Wachtwoord'],
      tenantColumns: [
        'Tenant Id',
        'Sender Contract Nummer',
        'Sender Id',
        'Betaling dagen geldig',
        'Betaling dagen tot verval',
      ],
      selectedOrganization: 0,
      selectedOrganizationIndex: 0,
      selectedTenantIndex: 0,
      organizations: [],
      bin: [],
      organizationInput: {
        id: '',
        name: '',
        orafin: false,
        pomUsername: '',
        pomPassword: '',
      },
      organizationEditInput: {
        id: '',
        name: '',
        orafin: false,
        pomUsername: '',
        pomPassword: '',
      },
      tenantInput: [],
      tenantEditInput: {
        id: '',
        senderContractNumber: '',
        senderId: '',
        dueDays: 0,
        expiryDays: 0,
      },
    };
  },
  methods: {
    //function to add data to table
    addOrganization: function() {
      try {
        this.$store.dispatch('organization/add', this.organizationInput).then(response => {
          response.tenants = [];
          this.organizations.push(response);
          for (const key in this.organizationInput) {
            if (key === 'orafin') {
              this.organizationInput[key] = false;
            } else {
              this.organizationInput[key] = '';
            }
            this.tenantInput.push({ id: '', senderContractNumber: '', senderId: '' });
          }
        });
      } catch (err) {
        this.error = err.message;
      }
    },
    //function to handle data edition
    editOrganization: function(organizationIndex) {
      this.organizationEditInput = { ...this.organizations[organizationIndex] };
      this.selectedOrganizationIndex = organizationIndex;
      $('#organizationModal').modal();
    },

    //function to update data
    updateOrganization: async function() {
      this.organizations.splice(this.selectedOrganizationIndex, 1);
      try {
        const organization = await this.$store.dispatch(
          'organization/update',
          this.organizationEditInput,
        );

        const tenants = await this.$store.dispatch(
          'tenant/getAllByOrganizationId',
          organization.id,
        );
        console.log(tenants);
        organization.tenants = [];

        organization.tenants = [...tenants];
        this.organizations.push(organization);

        for (const key in this.organizationEditInput) {
          if (key === 'orafin') {
            this.organizationEditInput[key] = false;
          } else {
            this.organizationEditInput[key] = '';
          }
        }
      } catch (err) {
        this.error = err.message;
      }
    },
    //function to defintely delete data
    deleteOrganization: function(organizationIndex) {
      if (confirm('Do you really want to delete?')) {
        try {
          this.$store
            .dispatch('organization/delete', this.organizations[organizationIndex].id)
            .then(() => {
              this.organizations.splice(organizationIndex, 1);
              this.tenantInput.splice(organizationIndex, 1);
            });
        } catch (err) {
          this.error = err.message;
        }
      }
    },
    addTenant: function(organizationIndex, organization) {
      try {
        this.tenantInput[organizationIndex].organizationId = organization.id;
        this.$store.dispatch('tenant/add', this.tenantInput[organizationIndex]).then(response => {
          organization.tenants.push(response);
          for (const key in this.tenantInput[organizationIndex]) {
            this.tenantInput[organizationIndex][key] = '';
          }
        });
      } catch (err) {
        this.error = err.message;
      }
    },
    //function to handle data edition
    editTenant: function(organizationIndex, tenantIndex) {
      this.tenantEditInput = { ...this.organizations[organizationIndex].tenants[tenantIndex] };
      this.selectedOrganizationIndex = organizationIndex;
      this.selectedTenantIndex = tenantIndex;
      $('#tenantModal').modal();
    },

    //function to update data
    updateTenant: function() {
      this.organizations[this.selectedOrganizationIndex].tenants.splice(
        this.selectedTenantIndex,
        1,
      );
      try {
        this.$store.dispatch('tenant/update', this.tenantEditInput).then(response => {
          this.organizations[this.selectedOrganizationIndex].tenants.push(response);
          for (const key in this.tenantEditInput) {
            this.tenantEditInput[key] = '';
          }
        });
      } catch (err) {
        this.error = err.message;
      }
    },
    //function to defintely delete data
    deleteTenant: function(organizationIndex, tenantIndex) {
      if (confirm('Do you really want to delete?')) {
        try {
          this.$store
            .dispatch(
              'tenant/delete',
              this.organizations[organizationIndex].tenants[tenantIndex].id,
            )
            .then(() => {
              this.organizations[organizationIndex].tenants.splice(tenantIndex, 1);
              this.$forceUpdate();
            });
        } catch (err) {
          this.error = err.message;
        }
      }
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
  },
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.css';
p,
button,
label,
input {
  font-size: 1.5rem;
}
.move-left {
  width: 2em;
}
.tenantTable tr td:last-child {
  width: 100%;
}
.opened {
  background-color: yellow;
}
</style>
